import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Input from "../Input/index";
import Button from "../Button/index";
import styled from "styled-components";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { editUserRecord } from "../../modules/Users/_redux/userCrud";
const ButtonContainer = styled.div`
text-align: center;
 }
`;
const validationSchema = Yup.object({
  phone_number: Yup.string().required("Phone Number is required"),
});
export default function Index({ data = null, setModal, fetchUsers }) {
  React.useEffect(() => {}, []);

  const initialValues = {
    first_name: data?.first_name || "",
    last_name: data?.last_name || "",
    phone_number: data?.phone_number || "",
    email_1: data?.email_1 || "",
    email_2: data?.email_2 || "",
    address_1: data?.address_1 || "",
    address_2: data?.address_2 || "",
    apple_id: data?.apple_id || "",
    city: data?.city || "",
    state: data?.state || "",
    zipcode: data?.zipcode || "",
    facebook: data?.facebook || "",
    tiktok: data?.tiktok || "",
    instagram: data?.instagram || "",
  };
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    enableReinitialize: true,

    onSubmit: (values, { resetForm }) => {
      if (data) {
        setModal(false);
        editUserRecord(data?._id, values)
          .then((response) => {
            fetchUsers();
          })
          .catch((err) => {
            console.log("This is error happed", err);
          });
      }
    },
  });
  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Container fluid="true">
          <Row>
            <Col>
              <Input
                label="First Name"
                //  required
                name="first_name"
                onChange={formik.handleChange}
                value={formik.values.first_name}
                // error={
                //   formik.touched.first_name && formik.errors.first_name
                //     ? formik.errors.first_name
                //     : null
                // }
              />
            </Col>
            <Col>
              <Input
                label="Last Name"
                //  required
                name="last_name"
                onChange={formik.handleChange}
                value={formik.values.last_name}
                // error={
                //   formik.touched.last_name && formik.errors.last_name
                //     ? formik.errors.last_name
                //     : null
                // }
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Input
                label="Email1"
                // required
                name="email_1"
                onChange={formik.handleChange}
                value={formik.values.email_1}
                // error={
                //   formik.touched.apple_id && formik.errors.email_1
                //     ? formik.errors.email_1
                //     : null
                // }
              />
            </Col>
            <Col>
              <Input
                label="Email2"
                //  required
                name="email_2"
                onChange={formik.handleChange}
                value={formik.values.email_2}
                // error={
                //   formik.touched.email_2 && formik.errors.email_2
                //     ? formik.errors.email_2
                //     : null
                // }
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Input
                label="Phone Number"
                required
                name="phone_number"
                onChange={formik.handleChange}
                value={formik.values.phone_number}
                error={
                  formik.touched.phone_number && formik.errors.phone_number
                    ? formik.errors.phone_number
                    : null
                }
              />
            </Col>
            <Col>
              <Input
                label="Apple ID"
                //   required
                name="apple_id"
                onChange={formik.handleChange}
                value={formik.values.apple_id}
                // error={
                //   formik.touched.apple_id && formik.errors.apple_id
                //     ? formik.errors.apple_id
                //     : null
                // }
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Input
                label="Address 1"
                name="address_1"
                onChange={formik.handleChange}
                value={formik.values.address_1}
              />
            </Col>
            <Col>
              <Input
                label="Adress 2"
                name="address_2"
                onChange={formik.handleChange}
                value={formik.values.address_2}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Input
                label="City"
                name="city"
                onChange={formik.handleChange}
                value={formik.values.city}
              />
            </Col>
            <Col>
              <Input
                label="State"
                name="state"
                onChange={formik.handleChange}
                value={formik.values.state}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Input
                label="Zipcode"
                name="zipcode"
                onChange={formik.handleChange}
                value={formik.values.zipcode}
              />
            </Col>
            <Col>
              <Input
                label="Facebook"
                name="facebook"
                onChange={formik.handleChange}
                value={formik.values.facebook}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Input
                label="TikTok"
                name="tiktok"
                onChange={formik.handleChange}
                value={formik.values.tiktok}
              />
            </Col>
            <Col>
              <Input
                label="Instagram"
                name="instagram"
                onChange={formik.handleChange}
                value={formik.values.instagram}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <ButtonContainer>
                {" "}
                <Button children={"Edit User"} type="submit" />
              </ButtonContainer>
            </Col>
          </Row>
        </Container>
      </form>
    </>
  );
}
