import axios from "axios";

export const signupUrl = `${process.env.REACT_APP_API_URL}/user/signup`;

export function inviteUser(data) {
  return axios.post("/user/signup", data);
}
export function getAllUsers() {
  return axios.get("/user/getAllUsers");
}
export function getAllBetaGroups() {
  return axios.get("/user/getBetaTesterGroups");
}
export function getS3DataUsage(id) {
  return axios.get(`/user/getUserDataUsage?id=${id}`);
}
export function deleteUserRecord(user_id) {
  return axios.post("/user/deleteUser", { user_id: user_id });
}
export function editUserRecord(user_id, data) {
  return axios.post(`/user/editUser?user_id=${user_id}`, data);
}
