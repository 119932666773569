import { ToastContainer, toast } from "react-toastify";

export default function setupAxios(axios, store) {
  //axios.defaults.baseURL = "http://10.28.86.66:2727/api";
  axios.defaults.baseURL =
    "https://dialin.tech/api";
  //"http://ec2-3-15-214-90.us-east-2.compute.amazonaws.com/api";
  //axios.defaults.baseURL = "http://dialin.io/dev/api";
  axios.interceptors.request.use(
    (config) => {
      const {
        auth: { authToken },
      } = store.getState();

      if (authToken) {
        config.headers.Authorization = `Bearer ${authToken}`;
      }

      return config;
    },
    (err) => Promise.reject(err)
  );
  axios.interceptors.response.use(
    (response) => {
      if (response.data.Status == 200) {
        toast.dismiss();
        toast.success(response.data.Message);
      }
      if (response.data.Status == 400) {
        toast.dismiss();
        toast.error(response.data.Message);
      }

      return response;
    },
    (error) => {
      toast.dismiss();
      if (error.response?.status === 401) {
        localStorage.removeItem("access_token");
        window.location.reload();
      } else if (error.response?.data?.error_description) {
        toast.error(error.response.data.error_description, {
          toastId: error.response.data.error_description,
        });
      } else if (error.response?.data?.message) {
        toast.error(error.response?.data?.message, {
          toastId: error.response?.data?.message,
        });
      } else if (error.response?.data?.error) {
        toast.error(error.response.data.error, {
          toastId: error.response.data.error,
        });
      } else if (error.response?.data) {
        toast.error(error.response?.data, {
          toastId: error.response?.data,
        });
      } else if (error.message) {
        toast.error(error.message, {
          toastId: error.message,
        });
      }
      throw error;
    }
  );
}
