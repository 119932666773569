import React from "react";
import SVG from "react-inlinesvg";

import { getQueueData } from "./queueapi";
import { useQuery } from "@tanstack/react-query";
import Button from "../../components/Button/index";
import { NavLink } from "react-router-dom";
import moment from "moment";
export default function QueueAnalytics(className) {
  const [queueData, setqueueData] = React.useState();

  const { data, refetch, isLoading } = useQuery({
    queryKey: ["queue-data"],
    queryFn: () => getQueueData(),
  });
  const records = React.useMemo(() => data?.data?.data || [], [
    data?.data?.data,
  ]);

  return (
    <>
      <div className={`card card-custom ${className}`}>
        <div className="card-header border-0 py-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label font-weight-bolder text-dark">
              Queue Analytics
            </span>
            <span className="text-muted mt-3 font-weight-bold font-size-sm">
              Ffmpeg Queue Analytics
            </span>
          </h3>
        </div>
        {/* end::Header */}

        {/* begin::Body */}
        <div className="card-body py-0">
          {/* begin::Table 1*/}
          <div className="table-responsive">
            <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
              {"In Progress Jobs"}
            </span>
            <table
              className="table table-head-custom table-vertical-center"
              id="kt_advance_table_widget_1"
            >
              <thead>
                <tr className="text-left">
                  <th className="pr-0" style={{ width: "50px" }}>
                    Path
                  </th>
                  <th style={{ minWidth: "200px" }} />
                  <th style={{ minWidth: "200px" }}> Start Time</th>

                  <th style={{ minWidth: "150px" }}>Status</th>
                </tr>
              </thead>
              {records?.InProgress?.[0]?.records?.map((queueInfo, index) => (
                <tbody>
                  <tr key={index}>
                    <td className="pl-0">{queueInfo?.path}</td>

                    <td></td>
                    <td>
                      {moment(queueInfo?.start_time).format(
                        "YYYY-MM-DD HH:mm a"
                      )}
                    </td>
                    <td>
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                        {queueInfo?.status}
                      </span>
                    </td>
                  </tr>
                </tbody>
              ))}
            </table>
          </div>
          {/* end::Table  1*/}
          {/* begin::Table 2*/}
          <div
            className="table-responsive"
            style={{ maxHeight: "400px", overflowY: "scroll" }}
          >
            <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
              {"Completed Jobs"}
            </span>
            <table
              className="table table-head-custom table-vertical-center"
              id="kt_advance_table_widget_1"
            >
              <thead>
                <tr className="text-left">
                  <th className="pr-0" style={{ width: "50px" }}>
                    Path
                  </th>
                  <th style={{ minWidth: "200px" }}> Start Time </th>
                  <th style={{ minWidth: "200px" }}> End Time </th>
                  <th style={{ minWidth: "200px" }}> Total Time Taken </th>
                  <th style={{ minWidth: "200px" }}> Activity Log </th>

                  <th style={{ minWidth: "150px" }}>Status</th>
                </tr>
              </thead>
              {records?.Completed?.[0]?.records?.map((queueInfo, index) => (
                <tbody>
                  <tr key={index}>
                    <td className="pl-0">{queueInfo?.path}</td>
                    <td>
                      {moment(queueInfo?.start_time).format(
                        "YYYY-MM-DD HH:mm a"
                      )}
                    </td>
                    <td>
                      {moment(queueInfo?.end_time).format("YYYY-MM-DD HH:mm a")}
                    </td>

                    <td>{`${parseFloat(queueInfo?.total_time).toFixed(
                      2
                    )} mins`}</td>
                    <td>{queueInfo?.activity_log}</td>
                    <td>
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                        {queueInfo?.status}
                      </span>
                    </td>
                  </tr>
                </tbody>
              ))}
            </table>
          </div>
          {/* end::Table  2*/}
          {/* begin::Table 3*/}
          <div
            className="table-responsive"
            style={{ maxHeight: "400px", overflowY: "scroll" }}
          >
            <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
              {"Failed Jobs"}
            </span>
            <table
              className="table table-head-custom table-vertical-center"
              id="kt_advance_table_widget_1"
            >
              <thead>
                <tr className="text-left">
                  <th className="pr-0" style={{ width: "50px" }}>
                    Path
                  </th>
                  <th style={{ minWidth: "200px" }}> Start Time </th>
                  <th style={{ minWidth: "200px" }}> Activity Log </th>

                  <th style={{ minWidth: "150px" }}>Status</th>
                </tr>
              </thead>
              {records?.Failed?.[0]?.records?.map((queueInfo, index) => (
                <tbody>
                  <tr key={index}>
                    <td className="pl-0">{queueInfo?.path}</td>
                    <td>
                      {moment(queueInfo?.start_time).format(
                        "YYYY-MM-DD HH:mm a"
                      )}
                    </td>

                    <td>{queueInfo?.activity_log}</td>

                    <td>
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                        {queueInfo?.status}
                      </span>
                    </td>
                  </tr>
                </tbody>
              ))}
            </table>
          </div>
          {/* end::Table  3*/}
        </div>
        {/* end::Body */}
      </div>
    </>
  );
}
