import React from "react";
import Modal from "../Modal";
import styled from "styled-components";
import Spinner from "react-bootstrap/Spinner";

const Button = styled.button`
  padding: 0.2rem 1rem !important;
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : "#1BC5BD"};
  border-color: ${(props) =>
    props.borderColor ? props.borderColor : "#1BC5BD"};
  font-size: 14px;
  color: ${(props) => (props.color ? props.color : "white")};
  &:hover {
    ${(props) => (props.hoverEffect ? props.hoverEffect : "color: white;")}
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  & * {
    margin: 2px;
  }
`;
const ButtonContainer = styled.div`
  & * {
    margin: 0 4px;
  }
`;

export default function Index({ openById, onclose, title, deleteRecord }) {
  const [showLoader, setShowLoader] = React.useState(false);
  return (
    <>
      <Modal
        open={!!openById}
        onclose={onclose}
        title={
          <Wrapper>
            <strong>{showLoader ? "Deleting User ..." : title}</strong>
          </Wrapper>
        }
        size={"md"}
        centered={true}
      >
        {showLoader ? (
          <Spinner animation="border" variant="warning" />
        ) : (
          <ButtonContainer className="d-flex align-items-center justify-content-center">
            <Button
              backgroundColor="#eb5423"
              borderColor="#eb5423"
              className={`btn text-uppercase`}
              onClick={() => {
                deleteRecord(openById);
                //  onclose();
                setShowLoader(true);
              }}
            >
              Delete
            </Button>
            <Button className={`btn text-uppercase`} onClick={onclose}>
              Cancel
            </Button>
          </ButtonContainer>
        )}
        {/* )} */}
      </Modal>
    </>
  );
}
