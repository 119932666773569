/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { Layout } from "../_metronic/layout";
import BasePage from "./BasePage";
import { Logout, AuthPage } from "./modules/Auth";
import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";
import MobileWebVeiw  from "./modules/MobileSideWebVeiw/index";
import MobileWebVeiwTest  from "./modules/MobileSideWebVeiw/tesssssstt";

// export function Routes() {
//   const { isAuthorized } = useSelector(
//     ({ auth }) => ({
//       isAuthorized: auth.user != null,
//     }),
//     shallowEqual
//   );
//   return (
    
//     <Switch>
//                 {/* <Route path="/veiw" component={MobileWebVeiw} /> */}
//                 {/* <Route path="/staging/veiw" component={MobileWebVeiw} /> */}
 
//       {!isAuthorized ? (
//         /*Render auth page when user at `/auth` and not authorized.*/
//         <Route>
//           <AuthPage />
//         </Route>
//       ) : (
//         /*Otherwise redirect to root page (`/`)*/
//         <Redirect from="/auth" to="/" />
//       )}

//       <Route path="/error" component={ErrorsPage} />
//       <Route path="/logout" component={Logout} />


//       {!isAuthorized ? (
//         /*Redirect to `/auth` when user is not authorized*/
//         <Redirect to="/auth/login" />
//       ) : (
//         <Layout>
//           <BasePage />
//         </Layout>
//       )}
//     </Switch>
//   );
// }
 
export function Routes() {
  const { isAuthorized } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.user != null,
    }),
    shallowEqual
  );

  return (
    <Switch>
      <Route exact path="/dev/view" component={MobileWebVeiw} />
      <Route exact path="/staging/view" component={MobileWebVeiw} />
      <Route exact path="/view" component={MobileWebVeiw} />
      <Route exact path="/test-view" component={MobileWebVeiwTest} />

      {/* Render auth page when user is at `/auth` and not authorized */}
      <Route path="/auth">
        {!isAuthorized ? <AuthPage /> : <Redirect to="/" />}
      </Route>
      <Route path="/error" component={ErrorsPage} />
       <Route path="/logout" component={Logout} />

      {/* Render BasePage within Layout when user is authorized */}
      <Route path="/">
        {isAuthorized ? (
          <Layout>
            <BasePage />
          </Layout>
        ) : (
          <Redirect to="/auth/login" />
        )}
      </Route>

      {/* Fallback route for handling 404 */}
      {/* <Route path="*" component={NotFoundPage} /> */}
    </Switch>
  );
}
