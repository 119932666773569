import React from "react";
import "./App.css"; // Import your CSS file for styling
import image from "./bg-4.jpg";
import logo from "./DialIn-Logo@3x.png";
import CountdownTimer from "./count-down-timer";
import { Container, Row, Col, Button } from "react-bootstrap";

function App({ topicDetails, itemType, signedUrl, cratedAt }) {
  return (
    <Container fluid className="background-image">
      <Row className="justify-content-center">
        <Col xs={12} md={8}>
          <div className="logo-container">
            <img src={logo} alt="Project Logo" className="logo" />
          </div>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col xs={12} md={8}>
          {itemType === "video" ? (
            <div className="main-video-container">
              <video controls className="video-container">
                <source src={signedUrl} type="video/mp4" />
                Your browser does not support the video tag.
              </video>

              <div className="justify-content-center buttons-row">
                <div className="rounded-button-container">
                  <div className="circle"></div>
                  <span className="btt-text">
                    <a
                      href="https://dialin.io"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      JOIN US
                    </a>
                  </span>
                </div>
                {topicDetails?.Dials?.map((dial) => (
                  <div className="rounded-button-container">
                    <div className="circle"></div>
                    <span className="btt-text">
                      {dial?.dial?.dial_id?.dial_name}
                    </span>
                  </div>
                ))}
              </div>
              <div> 

                 <CountdownTimer expirationTime ={cratedAt}/>
              </div>
              
            </div>
          ) : (
            <div className="main-video-container">
            <img
                src={signedUrl}
                alt="React Image"
                className="video-container"
              />
            <div className="justify-content-center buttons-row">
              <div className="rounded-button-container">
                <div className="circle"></div>
                <span className="btt-text">
                  <a
                    href="https://dialin.io"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    JOIN US
                  </a>
                </span>
              </div>
              {topicDetails?.Dials?.map((dial) => (
                <div className="rounded-button-container">
                  <div className="circle"></div>
                  <span className="btt-text">
                    {dial?.dial?.dial_id?.dial_name}
                  </span>
                </div>
              ))}
            </div>
            <div> 

               <CountdownTimer expirationTime ={cratedAt}/>
            </div>
            
          </div>
            
          )}
        </Col>
      </Row>
      
    </Container>
  );
}

export default App;
