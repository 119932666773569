import React from "react";
import Modal from "react-bootstrap/Modal";

export default function Index({
  open,
  onclose,
  title,
  children,
  size = "lg",
  ...props
}) {
  return (
    <>
      <Modal
        size={size}
        show={open}
        onHide={() => onclose()}
        aria-labelledby="modal-sizes-title-lg"
        {...props}
      >
        <Modal.Header closeButton>
          <Modal.Title id="modal-sizes-title-lg">{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{children}</Modal.Body>
      </Modal>
    </>
  );
}
