import React, { useState, useEffect } from 'react';

const CountdownTimer = ({ expirationTime }) => {
  const calculateTimeLeft = () => {
    const difference = new Date(expirationTime) - new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        hours: Math.floor(difference / (1000 * 60 * 60)),
        minutes: Math.floor((difference / (1000 * 60)) % 60)
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  return (
    <div className='button-text'>
      <div>
        <span>Expires In</span>
      {timeLeft.hours > 0 && (
         
        <div>
                  
          <span>{timeLeft.hours}</span> hours{' '}
        </div>
      )}
      {timeLeft.minutes > 0 && (
        <div>
          
          <span>{timeLeft.minutes}</span> minutes{' '}
        </div>
      )}
      {timeLeft.hours === 0 && timeLeft.minutes === 0 && (
        <div>Expired</div>
      )}
      
    </div>
    </div>
  );
};

export default CountdownTimer;
