// ItemDetailsPage.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import ExpiredPage  from "./expiredPage";
import ItemVeiw from "./itemView";
import TopicVeiw from "./topicVeiw";

const ItemDetailsPage = () => {
   const [itemDetails, setItemDetails] = useState(null);
   const [topicDetails, setTopicDetails] = useState(null);
   const [cratedAt, setcratedAt] = useState(null);
   const [itemType, setItemType] = useState(null);
   const [signedUrl, setSignedUrl] = useState(null);
   const [devSignedUrl, setDevSignedUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isExpired, setIsExpired] = useState(false);
  const urlParams = new URLSearchParams(window.location.search);
  const slug = urlParams.get('slug');
  const environment = urlParams.get('env');
  console.log("This is env",environment);
  const fetchData = async () => {
    try {
      let requestBody = {
        slug_id: slug,
        isDashBoard:true
      };
      console.log({ slug });
      let hitUrl= environment =="develop"?`https://dialin.tech/dev/api/get-slug-details`:environment =="staging"?`https://dialin.tech/staging/api/get-slug-details`:`https://dialin.tech/api/get-slug-details`;
      const response = await axios.post(hitUrl, requestBody);
      let { data } = response;
      console.log("Data:", data?.Data?.type);
      console.log("Hello response here2222",typeof data?.Data?.slug_id);
      let item_id=data?.Data?.slug_id;
      let user_id=data?.Data?.user_id;
      let access_token=data?.Data?.user_token;
      setcratedAt(data?.Data?.expiration_time);
      if(data?.Data?.type==="item"){
        let requestBody1 = {
          item_id:item_id,
          user_id:user_id,
          isDashBoard:true
        };
        let hitUrl= environment =="develop"?`https://dialin.tech/dev/api/item/item_detail`:environment =="staging"?`https://dialin.tech/staging/api/item/item_detail`:`https://dialin.tech/api/item/item_detail`;
       let response = await axios.post(hitUrl, requestBody1,{
        headers: {
          Authorization:access_token // Add the token to the Authorization header
        }
      });
      let { data } = response;
       if(data?.Data?.item_detail?.item_type==="video"){
           setItemType("video");
           let hitUrl= environment =="develop"?`https://dialin.tech/dev/api/getsignedurl`:environment =="staging"?`https://dialin.tech/staging/api/getsignedurl`:`https://dialin.tech/api/getsignedurl`;
           let requestBody2 = {
            image_key:data?.Data?.item_detail?.video,
            user_id:user_id,
            isDashBoard:true
          };
           let response = await axios.post(hitUrl, requestBody2,{
            headers: {
              Authorization: access_token // Add the token to the Authorization header
            }
          });
          console.log("Signed urls",response?.data?.Data);
          setSignedUrl(response?.data?.Data);
       }
       if(data?.Data?.item_detail?.item_type==="image"){
           setItemType("image");
           let hitUrl= environment =="develop"?`https://dialin.tech/dev/api/getsignedurl`:environment =="staging"?`https://dialin.tech/staging/api/getsignedurl`:`https://dialin.tech/api/getsignedurl`;
           let requestBody2 = {
            image_key:data?.Data?.item_detail?.image,
            user_id:user_id,
            isDashBoard:true
          };
           let response = await axios.post(hitUrl, requestBody2,{
            headers: {
              Authorization: access_token // Add the token to the Authorization header
            }
          });
           setSignedUrl(response?.data?.Data);
       }
       if(data?.Data?.item_detail?.item_type==="pdf"){
           setItemType("pdf");
           let hitUrl= environment =="develop"?`https://dialin.tech/dev/api/getsignedurl`:environment =="staging"?`https://dialin.tech/staging/api/getsignedurl`:`https://dialin.tech/api/getsignedurl`;
           let requestBody2 = {
            image_key:data?.Data?.item_detail?.attachment,
            user_id:user_id,
            isDashBoard:true
          };
           let response = await axios.post(hitUrl, requestBody2,{
            headers: {
              Authorization: access_token // Add the token to the Authorization header
            }
          });
           setSignedUrl(response?.data?.Data);
       }
       if(data?.Data?.item_detail?.item_type==="audio"){
           setItemType("audio");
           let hitUrl= environment =="develop"?`https://dialin.tech/dev/api/getsignedurl`:environment =="staging"?`https://dialin.tech/staging/api/getsignedurl`:`https://dialin.tech/api/getsignedurl`;
           let requestBody2 = {
            image_key:data?.Data?.item_detail?.attachment,
            user_id:user_id,
              isDashBoard:true
          };
           let response = await axios.post(hitUrl, requestBody2,{
            headers: {
              Authorization: access_token // Add the token to the Authorization header
            }
          });
           let hitUrl1= environment =="develop"?`https://dialin.tech/dev/api/getsignedurl`:environment =="staging"?`https://dialin.tech/staging/api/getsignedurl`:`https://dialin.tech/api/getsignedurl`;
           let requestBody1 = {
            image_key:data?.Data?.item_detail?.item_thumbnail,
            user_id:user_id,
             isDashBoard:true
          };
           let response1 = await axios.post(hitUrl1, requestBody1,{
            headers: {
              Authorization: access_token // Add the token to the Authorization header
            }
          });
           setSignedUrl(response?.data?.Data);
           setDevSignedUrl(response1?.data?.Data)
       }
      setItemDetails(data?.Data);

       console.log("Hello response here",response);
      }


      ///////topic detail page
      if(data?.Data?.type==="topic"){
        let requestBody1 = {
          topic_id:item_id,
          user_id:user_id,
        };
        let hitUrl= environment =="develop"?`https://dialin.tech/dev/api/topic/topic_detail`:environment =="staging"?`https://dialin.tech/staging/api/topic/topic_detail`:`https://dialin.tech/api/topic/topic_detail`;
       let response = await axios.post(hitUrl, requestBody1,{
        headers: {
          Authorization:access_token // Add the token to the Authorization header
        }
      });
      let { data } = response;
      console.log("These data here",data);
       if(data?.Data?.Topic?.image){
           setItemType("image");
           let hitUrl= environment =="develop"?`https://dialin.tech/dev/api/getsignedurl`:environment =="staging"?`https://dialin.tech/staging/api/getsignedurl`:`https://dialin.tech/api/getsignedurl`;
           let requestBody2 = {
            image_key:data?.Data?.Topic?.image,
            user_id:user_id,
            isDashBoard:true
          };
           let response = await axios.post(hitUrl, requestBody2,{
            headers: {
              Authorization: access_token // Add the token to the Authorization header
            }
          });
          console.log("Signed urls",response?.data?.Data);
          setSignedUrl(response?.data?.Data);
       }
       else{
        if (data?.Data?.all_data[0]?.data?.item_detail?.item_type==="image"){
          setItemType("image");
          let hitUrl= environment =="develop"?`https://dialin.tech/dev/api/getsignedurl`:environment =="staging"?`https://dialin.tech/staging/api/getsignedurl`:`https://dialin.tech/api/getsignedurl`;
          let requestBody2 = {
           image_key:data?.Data?.all_data[0]?.data?.item_detail?.image,
           user_id:user_id,
           isDashBoard:true
         };
          let response = await axios.post(hitUrl, requestBody2,{
           headers: {
             Authorization: access_token // Add the token to the Authorization header
           }
         });
          setSignedUrl(response?.data?.Data);
        }else{
          setItemType("video");
          let hitUrl= environment =="develop"?`https://dialin.tech/dev/api/getsignedurl`:environment =="staging"?`https://dialin.tech/staging/api/getsignedurl`:`https://dialin.tech/api/getsignedurl`;
          let requestBody2 = {
           image_key:data?.Data?.all_data[0]?.data?.item_detail?.video,
           user_id:user_id,
           isDashBoard:true
         };
          let response = await axios.post(hitUrl, requestBody2,{
           headers: {
             Authorization: access_token // Add the token to the Authorization header
           }
         });
          setSignedUrl(response?.data?.Data);
        }
           
          
       }
      setTopicDetails(data?.Data);

       console.log("Hello response here",response);
      }


      if (data?.Data == null) {
        console.log("Data is null, setting isExpired to true");
        setIsExpired(true);
      }
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching item details:', error);
      setIsLoading(false);
    }
  };
  
  // Call fetchData and wait for its completion
  const loadData = async () => {
    setIsLoading(true);
    try {
      await fetchData();
    } catch (error) {
      console.error('Error loading data:', error);
    }
  };
  
  // Invoke loadData when needed, ensuring it's awaited
  // For example, in a useEffect hook
  useEffect(() => {
    loadData();
  }, []); // Run only once when the component mounts
  
  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isExpired) {
    return <ExpiredPage/>;
  }

 
console.log("hellllooooo hi",itemType,signedUrl);
  return (
    <div>
      {itemDetails&&
      <ItemVeiw itemDetails={itemDetails} itemType={itemType} signedUrl={signedUrl} cratedAt={cratedAt} devSignedUrl={devSignedUrl}/>
}
      {topicDetails&&
      <TopicVeiw topicDetails={topicDetails} itemType={itemType} signedUrl={signedUrl} cratedAt={cratedAt}/>
}
      {/* <p>{itemDetails.description}</p> */}
    </div>
  );
};

export default ItemDetailsPage;
