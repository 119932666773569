import { getAllUsers, getAllBetaGroups, getS3DataUsage } from "./userCrud";
import { UsersSlice } from "./userSlice";
const { actions } = UsersSlice;

export const getUsersList = (searchParams) => (dispatch) => {
  dispatch(actions.setLoading());
  return getAllUsers(searchParams)
    .then((response) => {
      dispatch(actions.setUsersList({ data: response.data?.data?.user }));
      dispatch(actions.setLoading(false));
    })
    .catch((err) => {
      console.log("erere", err);
      dispatch(actions.setLoading(false));
    });
};
export const getBetaTesterGroups = (searchParams) => (dispatch) => {
  return getAllBetaGroups(searchParams)
    .then((response) => {
      dispatch(actions.setBetaGroups({ data: response.data?.data?.groups }));
    })
    .catch((err) => {
      console.log("erere", err);
      dispatch(actions.setLoading(false));
    });
};
export const getUserS3DataUsage = (searchParams) => (dispatch) => {
  return getS3DataUsage(searchParams)
    .then((response) => {
      dispatch(
        actions.setUserS3DataUsage({ data: response.data?.data?.totalUsage })
      );
    })
    .catch((err) => {
      console.log("erere", err);
      dispatch(actions.setLoading(false));
    });
};
// export const deleteUsersRecord = (id) => (dispatch) => {
//   return deleteUserRecord(id)
//     .then((response) => {
//       // dispatch(actions.setBetaGroups({ data: response.data?.data?.groups }));
//     })
//     .catch((err) => {
//       console.log("erere", err);
//       dispatch(actions.setLoading(false));
//     });
// };
