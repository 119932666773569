import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userAnalytics: [],
};

export const UsersAnalyticsSlice = createSlice({
  name: "analytics",
  initialState: initialState,
  reducers: {
    setUsersAnalytics: (state, action) => {
      console.log("Action action", action);
      const analytics = action.payload?.data;
      state.userAnalytics = analytics;
      //   state.totalUsers = totalItems;
    },
    setLoading: (state, action) => {
      const value = action.payload;
      state.isLoading = !!value ? value : !state.isLoading;
    },
  },
});
