import React from "react";
import "./App.css"; // Import your CSS file for styling
import image from "./bg-4.jpg";
import logo from "./DialIn-Logo@3x.png";
import CountdownTimer from "./count-down-timer";
import { Container, Row, Col, Button } from "react-bootstrap";

function App() {
  return (
    <Container fluid className="background-image ">
      <Row className="justify-content-center">
        <Col xs={12} md={8}>
          <div className="logo-container">
            <img src={logo} alt="Project Logo" className="logo" />
          </div>
        </Col>
      </Row>
     
      
    </Container>
  );
}

export default App;
