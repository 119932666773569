import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  usersList: [],
  totalUsers: 0,
  betaGroups: [],
  dataUsage: null,
  isLoading: false,
};

export const UsersSlice = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {
    setUsersList: (state, action) => {
      const user = action.payload?.data;
      state.usersList = user;
      //   state.totalUsers = totalItems;
    },
    setBetaGroups: (state, action) => {
      const groups = action.payload?.data;
      state.betaGroups = groups;
      //   state.totalUsers = totalItems;
    },
    setUserS3DataUsage: (state, action) => {
      const usage = action.payload?.data;
      state.dataUsage = usage;
      //   state.totalUsers = totalItems;
    },
    setLoading: (state, action) => {
      const value = action.payload;
      state.isLoading = !!value ? value : !state.isLoading;
    },
  },
});
