import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import Modal from "../../components/Modal/index";
import UserInviteForm from "../../components/UserForm/index";
import UserEditForm from "../../components/UserForm/editUserForm";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { getUsersList } from "./_redux/userActions";
import Button from "../../components/Button/index";
import { InfoDialog } from "../../components/DialogBox/index";
import ConfirmationBox from "../../components/confirmationBox/index";
import { deleteUserRecord } from "../../../app/modules/Users/_redux/userCrud";
import { NavLink } from "react-router-dom";
export default function UserList(className) {
  const [modal, setModal] = React.useState(false);
  const [userInfo, setUser] = React.useState(false);
  const [showUserData, setshowUserData] = React.useState(false);
  const [showEditFrom, setshowEditFrom] = React.useState(false);
  const [deleteModal, setDeleteModal] = React.useState(false);
  const [deleteId, setDeleteId] = React.useState();
  const [userData, setuserData] = React.useState();
  const { usersList } = useSelector((state) => state.user, shallowEqual);
  const dispatch = useDispatch();
  React.useEffect(() => {
    fetchUsers();
  }, []);
  const fetchUsers = () => {
    dispatch(getUsersList());
  };
  const deleteRecord = () => {
    deleteUserRecord(deleteId)
      .then((response) => {
        setDeleteModal(false);
        fetchUsers();
      })
      .catch((err) => {
        console.log("This is err", err);
      });
  };

  return (
    <>
      <ConfirmationBox
        openById={deleteModal}
        onclose={() => setDeleteModal(false)}
        deleteRecord={deleteRecord}
        title="Are You Sure You Want to Delete This User?"
      />
      <Modal
        open={modal}
        onclose={() => {
          setModal(false);
        }}
        title={
          showUserData
            ? `S3 Data Usage And Price for ${userInfo?.first_name +
                userInfo?.last_name}`
            : showEditFrom
            ? "Edit User"
            : "Invite A New User"
        }
      >
        {showUserData ? (
          <InfoDialog userInfo={userInfo} />
        ) : showEditFrom ? (
          <UserEditForm
            data={userData}
            setModal={setModal}
            fetchUsers={fetchUsers}
          />
        ) : (
          <UserInviteForm setModal={setModal} fetchUsers={fetchUsers} />
        )}
      </Modal>
      <div className={`card card-custom ${className}`}>
        <div className="card-header border-0 py-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label font-weight-bolder text-dark">
              Users
            </span>
            <span className="text-muted mt-3 font-weight-bold font-size-sm">
              Users Regitered In the App
            </span>
          </h3>
          <div className="card-toolbar">
            <a
              href="#"
              className="btn btn-success font-weight-bolder font-size-sm"
              onClick={() => {
                setModal(true);
                setshowUserData(false);
                setDeleteModal(false);
                setshowEditFrom(false);
                setshowUserData(false);
              }}
            >
              <span className="svg-icon svg-icon-md svg-icon-white">
                <SVG
                  src={toAbsoluteUrl(
                    "/media/svg/icons/Communication/Add-user.svg"
                  )}
                  className="h-50 align-self-center"
                ></SVG>
              </span>
              Invite New User
            </a>
          </div>
        </div>
        {/* end::Header */}

        {/* begin::Body */}
        <div className="card-body py-0">
          {/* begin::Table */}
          <div className="table-responsive">
            <table
              className="table table-head-custom table-vertical-center"
              id="kt_advance_table_widget_1"
            >
              <thead>
                <tr className="text-left">
                  <th className="pl-0" style={{ width: "20px" }}>
                    <label className="checkbox checkbox-lg checkbox-single">
                      <input type="checkbox" value="1" />
                      <span></span>
                    </label>
                  </th>
                  <th className="pr-0" style={{ width: "50px" }}>
                    Name
                  </th>
                  <th style={{ minWidth: "200px" }} />
                  <th style={{ minWidth: "150px" }}>Phone Number</th>
                  <th style={{ minWidth: "150px" }}>S3 Data Usage</th>
                  <th className="pr-0 text-right" style={{ minWidth: "150px" }}>
                    action
                  </th>
                </tr>
              </thead>
              {usersList?.map((userInfo, index) => (
                <tbody>
                  <tr key={index}>
                    <td className="pl-0">
                      <label className="checkbox checkbox-lg checkbox-single">
                        <input type="checkbox" value="1" />
                        <span></span>
                      </label>
                    </td>
                    <td className="pr-0">
                      <div className="symbol symbol-50 symbol-light mt-1">
                        <span className="symbol-label">
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/avatars/001-boy.svg"
                            )}
                            className="h-75 align-self-end"
                          ></SVG>
                        </span>
                      </div>
                    </td>
                    <td className="pl-0">
                      <a
                        href="#"
                        className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                      >
                        {userInfo?.first_name + userInfo?.last_name}
                      </a>
                      {/* <span className="text-muted font-weight-bold text-muted d-block">
                        HTML, JS, ReactJS
                      </span> */}
                    </td>
                    <td>
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                        {userInfo?.phone_number}
                      </span>
                      {/* <span className="text-muted font-weight-bold">
                        Web, UI/UX Design
                      </span> */}
                    </td>
                    <td>
                      <div className="d-flex flex-column w-100 mr-2">
                        <div className="d-flex align-items-center justify-content-between mb-2">
                          {/* <span className="text-muted mr-2 font-size-sm font-weight-bold">
                            65%
                          </span> */}
                          <span>
                            <Button
                              children={"See S3 Data Usage"}
                              type="button"
                              onClick={() => {
                                setModal(true);
                                setshowUserData(true);
                                setUser(userInfo);
                                setshowEditFrom(false);
                                setDeleteModal(false);
                              }}
                            />
                          </span>
                        </div>
                      </div>
                    </td>
                    <td className="pr-0 text-right">
                      <a
                        href="#"
                        className="btn btn-icon btn-light btn-hover-primary btn-sm"
                      >
                        <NavLink
                          className="menu-link"
                          to={`/user/analytics/${userInfo?._id}`}
                        >
                          <span className="svg-icon svg-icon-md svg-icon-primary">
                            <SVG
                              src={toAbsoluteUrl(
                                "/media/svg/icons/General/Settings-1.svg"
                              )}
                            ></SVG>
                          </span>
                        </NavLink>
                      </a>
                      <a
                        href="#"
                        className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                      >
                        <span
                          className="svg-icon svg-icon-md svg-icon-primary"
                          onClick={() => {
                            setModal(true);
                            setshowEditFrom(true);
                            setDeleteModal(false);
                            setshowUserData(false);
                            setuserData(userInfo);
                          }}
                        >
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Communication/Write.svg"
                            )}
                          ></SVG>
                        </span>
                      </a>
                      <a
                        href="#"
                        className="btn btn-icon btn-light btn-hover-primary btn-sm"
                      >
                        <span className="svg-icon svg-icon-md svg-icon-primary">
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/General/Trash.svg"
                            )}
                            onClick={() => {
                              setDeleteModal(true);
                              setDeleteId(userInfo?._id);
                              setshowEditFrom(false);
                              setshowUserData(false);
                            }}
                          ></SVG>
                        </span>
                      </a>
                    </td>
                  </tr>
                </tbody>
              ))}
            </table>
          </div>
          {/* end::Table */}
        </div>
        {/* end::Body */}
      </div>
    </>
  );
}
