import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Input from "../Input/index";
import Button from "../Button/index";
import styled from "styled-components";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { inviteUser } from "../../modules/Users/_redux/userCrud";
import Select from "../../components/Select/index";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { getBetaTesterGroups } from "../../modules/Users/_redux/userActions";
const ButtonContainer = styled.div`
text-align: center;
 }
`;
const validationSchema = Yup.object({
  f_name: Yup.string().required("User Name is required"),
  apple_id: Yup.string().required("Apple Id is required"),
  phone_number: Yup.string().required("Phone Number is required"),
  betaGroup: Yup.string().required("Please Select a Group"),
});
export default function Index({ data = null, setModal, fetchUsers }) {
  const { betaGroups } = useSelector((state) => state.user, shallowEqual);
  const dispatch = useDispatch();

  React.useEffect(() => {
    fetchBetaGroups();
  }, []);
  const fetchBetaGroups = () => {
    dispatch(getBetaTesterGroups());
  };
  const initialValues = {
    f_name: data?.f_name || "",
    l_name: data?.l_name || "",
    apple_id: data?.apple_id || "",
    phone_number: data?.phone_number || "",
    betaGroup: data?.betaGroup || "",
  };
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    enableReinitialize: true,

    onSubmit: (values, { resetForm }) => {
      // if () {
      //   // updateItemMutate(
      //   //   { ...values, itemId: data._id },
      //   //   {
      //   //     onSuccess: (data) => {
      //   //       setEditableProperties(data.item);
      //   //       onSubmit();
      //   //     },
      //   //   }
      //   //  );
      // } else {
      setModal(false);
      let valueObject = {
        f_name: values?.f_name,
        l_name: values?.l_name,
        apple_id: values?.apple_id,
        phone_number: values?.phone_number,
        user_type: "Admin",
        groupId: values?.betaGroup?.id,
      };
      console.log("values here", values);
      inviteUser(valueObject).then((response) => {
        fetchUsers();
      });
      //  }
    },
  });
  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Container fluid="true">
          <Row>
            <Col>
              <Input
                label="First Name"
                required
                name="f_name"
                onChange={formik.handleChange}
                //  onBlur={formik.handleBlur}
                value={formik.values.f_name}
                error={
                  formik.touched.f_name && formik.errors.f_name
                    ? formik.errors.f_name
                    : null
                }
              />
            </Col>
            <Col>
              <Input
                label="Last Name"
                required
                name="l_name"
                onChange={formik.handleChange}
                //  onBlur={formik.handleBlur}
                value={formik.values.l_name}
                error={
                  formik.touched.l_name && formik.errors.l_name
                    ? formik.errors.l_name
                    : null
                }
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Input
                label="Apple ID"
                required
                name="apple_id"
                onChange={formik.handleChange}
                //  onBlur={formik.handleBlur}
                value={formik.values.apple_id}
                error={
                  formik.touched.apple_id && formik.errors.apple_id
                    ? formik.errors.apple_id
                    : null
                }
              />
            </Col>
            <Col>
              <Input
                label="Phone Number"
                required
                name="phone_number"
                onChange={formik.handleChange}
                //  onBlur={formik.handleBlur}
                value={formik.values.phone_number}
                error={
                  formik.touched.phone_number && formik.errors.phone_number
                    ? formik.errors.phone_number
                    : null
                }
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Select
                label="Select Beta Tester Group"
                required
                options={betaGroups ? betaGroups : null}
                getOptionLabel={(option) => option?.attributes?.name}
                getOptionValue={(option) => option?.id}
                name="betaGroup"
                onChange={(e) => {
                  formik.setFieldValue("betaGroup", e);
                }}
                // onBlur={formik.handleBlur}
                value={formik.values.betaGroup}
                error={
                  formik.touched.betaGroup && formik.errors.betaGroup
                    ? formik.errors.betaGroup
                    : null
                }
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <ButtonContainer>
                {" "}
                <Button children={"Invite User"} type="submit" />
              </ButtonContainer>
            </Col>
          </Row>
        </Container>
      </form>
    </>
  );
}
