import React from "react";
import Select from "react-select";

import styled from "styled-components";

const Required = styled.span`
  color: #dc3545;
`;

const Label = styled.label`
  margin-bottom: 0.11rem;
  color: #495057;
  font-size: 0.8rem;
`;

const FormGroup = styled.div`
  margin-bottom: 0.9rem;
  line-height: 1rem;
`;
const Error = styled.div`
  color: #dc3545;
  font-size: 12px;
  padding: 2px 0;
`;

export default function Index({
  control = {},
  error,
  label,
  required,
  ...props
}) {
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      textTransform: "capitalize",
      "&:hover": {
        background: "#1BC5BD",
        color: "white",
      },
    }),
    menu: (provided, state) => ({
      ...provided,
      marginTop: 0,
      top: "95%",
      boxShadow: "none",
      outline: "initial !important",
      border: "1px solid #d7d7d7",
      borderTop: "none",
    }),
    control: (base, state) => ({
      ...base,
      textTransform: "capitalize",
      borderRadius: "0.1rem",
      outline: "initial !important",
      boxShadow: "none !important",
      fontSize: "0.8rem",
      lineHeight: 1.5,
      minHeight: "42px",
      width: "100%",
      border: "1px solid #d7d7d7",
      background: "#fff",
      color: "#212121",
      "&:focus": {
        border: "1px solid #145388",
        color: "white",
      },
      ...control,
    }),
  };
  return (
    <>
      <FormGroup>
        {label && (
          <Label htmlFor={label}>
            <span>{label}</span>
            {required && <Required className="required"> * </Required>}
          </Label>
        )}

        <Select
          components={{
            IndicatorSeparator: () => null,
          }}
          styles={customStyles}
          {...props}
        />
        <Error> {error}</Error>
      </FormGroup>
    </>
  );
}
