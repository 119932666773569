import React, { useEffect, useState } from "react";
import { getS3DataUsage } from "../../modules/Users/_redux/userCrud";
import Spinner from "react-bootstrap/Spinner";
export function InfoDialog({ userInfo }) {
  const [usage, setUsage] = React.useState(null);
  React.useEffect(() => {
    getS3DataUsage(userInfo?._id).then((response) => {
      setUsage(response.data?.data?.totalUsage);
    });
  }, []);
  return (
    <>
      <h3>
        {usage !== null ? (
          `Total: ${usage}MB`
        ) : (
          <>
            <span className="visually-hidden">Calculating Usage... </span>
            <Spinner animation="border" role="status"></Spinner>
          </>
        )}
      </h3>
      <h3>
        {usage !== null ? (
          `Total Price: $${parseFloat(0.022 * (usage / 1000)).toFixed(3)}`
        ) : (
          <>
            <span className="visually-hidden">Calculating Price.... </span>
            <Spinner animation="border" role="status"></Spinner>
          </>
        )}
      </h3>
    </>
  );
}
