import React from 'react';
import { Container, Alert } from 'react-bootstrap';

const ExpiredPage = () => {
  return (
    <Container className="mt-5">
      <Alert variant="danger">
        <Alert.Heading>Oops! This URL has expired.</Alert.Heading>
        <p>
          The page you are trying to access has expired. Please contact the
          sender for an updated link.
        </p>
      </Alert>
    </Container>
  );
};

export default ExpiredPage;
